// https://www.esma.europa.eu/sites/default/files/library/2016-1452_guidelines_mifid_ii_transaction_reporting.pdf

export interface MifidConcatDataDate {
    year: string;
    month: string;
    day: string;
}

export interface MifidConcatData {
    firstName: string;
    lastName: string;
    dob: MifidConcatDataDate | Date;
    countryCode: string;
}

const characterNormalisationTable = [
    {
        new: 'A',
        old: [
            'Ä',
            'ä',
            'À',
            'à',
            'Á',
            'á',
            'Â',
            'â',
            'Ã',
            'ã',
            'Å',
            'å',
            'Ǎ',
            'ǎ',
            'Ą',
            'ą',
            'Ă',
            'ă',
            'Æ',
            'æ',
            'a',
        ],
    },
    { new: 'B', old: ['b'] },
    { new: 'C', old: ['Ç', 'ç', 'Ć', 'ć', 'Ĉ', 'ĉ', 'Č', 'č', 'c'] },
    { new: 'D', old: ['Ď', 'đ', 'Đ', 'ď', 'ð', 'd'] },
    {
        new: 'E',
        old: ['È', 'è', 'É', 'é', 'Ê', 'ê', 'Ë', 'ë', 'Ě', 'ě', 'Ę', 'ę', 'e'],
    },
    { new: 'F', old: ['f'] },
    { new: 'G', old: ['Ĝ', 'ĝ', 'Ģ', 'ģ', 'Ğ', 'ğ', 'g'] },
    { new: 'H', old: ['Ĥ', 'ĥ', 'h'] },
    { new: 'I', old: ['Ì', 'ì', 'Í', 'í', 'Î', 'î', 'Ï', 'ï', 'ı', 'i'] },
    { new: 'J', old: ['Ĵ', 'ĵ', 'j'] },
    { new: 'K', old: ['Ķ', 'ķ', 'k'] },
    { new: 'L', old: ['ĺ', 'Ļ', 'ļ', 'Ł', 'ł', 'Ľ', 'ľ', 'l'] },
    { new: 'M', old: ['m'] },
    { new: 'N', old: ['Ñ', 'ñ', 'Ń', 'ń', 'Ň', 'ň', 'n'] },
    {
        new: 'O',
        old: [
            'Ö',
            'ö',
            'Ò',
            'ò',
            'Ó',
            'ó',
            'Ô',
            'ô',
            'Õ',
            'õ',
            'Ő',
            'ő',
            'Ø',
            'ø',
            'Œ',
            'œ',
            'o',
        ],
    },
    { new: 'P', old: ['p'] },
    { new: 'Q', old: ['q'] },
    { new: 'R', old: ['Ŕ', 'ŕ', 'Ř', 'ř', 'r'] },
    {
        new: 'S',
        old: ['ẞ', 'ß', 'Ś', 'ś', 'Ŝ', 'ŝ', 'Ş', 'ş', 'Š', 'š', 'Ș', 'ș', 's'],
    },
    { new: 'T', old: ['Ť', 'ť', 'Ţ', 'ţ', 'Þ', 'þ', 'Ț', 'ț', 't'] },
    {
        new: 'U',
        old: [
            'Ü',
            'ü',
            'Ù',
            'ù',
            'Ú',
            'ú',
            'Û',
            'û',
            'Ű',
            'ű',
            'Ũ',
            'ũ',
            'Ų',
            'ų',
            'Ů',
            'ů',
            'u',
        ],
    },
    { new: 'V', old: ['v'] },
    { new: 'W', old: ['Ŵ', 'ŵ', 'w'] },
    { new: 'X', old: ['x'] },
    { new: 'Y', old: ['Ý', 'ý', 'Ÿ', 'ÿ', 'Ŷ', 'ŷ', 'y'] },
    { new: 'Z', old: ['Ź', 'ź', 'Ž', 'ž', 'Ż', 'ż', 'z'] },
];

// Please consider order when adding pre fixes if van is executed before van der it will only remove van
const prefixesToRemove = [
    'AM',
    'AUF',
    'AUF DEM',
    'AUS DER',
    'D',
    'DA',
    'DE L’',
    "DE L'",
    'DEL',
    'DE LA',
    'DE LE',
    'DE',
    'DI',
    'DO',
    'DOS',
    'DU',
    'IM',
    'LA',
    'LE',
    'MAC',
    'MC',
    'MHAC',
    'MHIC GIOLLA',
    'MHÍC',
    'MIC',
    'NI',
    'NÍ',
    'NÍC',
    'O',
    'Ó',
    'UA',
    'UI',
    'UÍ',
    'VAN DER WAN',
    'VAN DE',
    'VAN DEN',
    'VAN DER',
    'VAN',
    'VOM',
    'VON DEM',
    'VON DEN',
    'VON DER',
    'VON',
];

export class MifidConcat {
    public static generateConcatIdentifier(data: MifidConcatData) {
        const { firstName, lastName, dob, countryCode } = data;
        const dateString = this.formattedDate(dob);
        const name1 = this.sliceOrPad(
            this.removeInvalidCharacers(
                this.normaliseCharacters(this.cleanFirstName(firstName.trim()))
            )
        );
        const name2 = this.sliceOrPad(
            this.removeInvalidCharacers(
                this.removePrefixes(this.normaliseCharacters(lastName.trim()))
            )
        );
        return `${countryCode}${dateString}${name1}${name2}`;
    }

    private static cleanFirstName(input: string): string {
        return input.split(' ').filter(Boolean)[0];
    }

    private static normaliseCharacters(input: string): string {
        return input
            .split('')
            .map(
                (char) =>
                    characterNormalisationTable.find(({ old }) =>
                        old.includes(char)
                    )?.new || char
            )
            .join('');
    }

    private static removePrefixes(input: string): string {
        const prefix = prefixesToRemove.find((prefix) =>
            input.startsWith(prefix + ' ')
        );
        if (prefix) {
            return input.slice(prefix.length);
        }
        return input;
    }

    private static removeInvalidCharacers(input: string): string {
        return input.replaceAll(/[^A-Z]/g, '');
    }

    private static sliceOrPad(input: string): string {
        if (input.length < 5) {
            return input + new Array(5 - input.length).fill('#').join('');
        }
        return input.slice(0, 5);
    }

    private static formattedDate(input: Date | MifidConcatDataDate): string {
        if ((input as Date).getMonth) {
            const date = input as Date;
            const month =
                date.getMonth().toString().length === 2
                    ? date.getMonth() + 1
                    : '0' + (date.getMonth() + 1);
            const day =
                date.getDate().toString().length === 2
                    ? date.getDate()
                    : '0' + date.getDate();
            return `${date.getFullYear()}${month}${day}`;
        }
        const date = input as MifidConcatDataDate;
        const month = date.month.length === 2 ? date.month : '0' + date.month;
        const day = date.day.length === 2 ? date.day : '0' + date.day;
        return `${date.year}${month}${day}`;
    }
}
