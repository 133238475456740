import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
    DEFAULT_INPUT_RICH_TEXT_FONT_FAMILY,
    IThemeService,
} from '@wdx/shared/utils';
import {
    BehaviorSubject,
    delay,
    filter,
    map,
    pairwise,
    startWith,
    takeUntil,
    tap,
} from 'rxjs';
import showdown from 'showdown';
import { Editor, RawEditorOptions } from 'tinymce';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { IFormDynamicData } from '../../../interfaces';

@Component({
    selector: 'wdx-ff-rich-text-control',
    templateUrl: './form-rich-text-control.component.html',
})
export class FormRichTextControlComponent
    extends BaseWdxFormControlClass
    implements OnInit
{
    editorApiKey = 'hq75q3gj9wdn5of71vw6i0ly4et010pmlekas8oddc053jls';
    editorConfig: RawEditorOptions = {
        selector: 'textarea',
        menubar: false,
        plugins: 'autolink link lists autoresize code',
        toolbar:
            'fontfamily fontsize bold italic underline forecolor | link | bullist numlist',
        branding: false,
        height: 300,
        statusbar: false,
        content_css: 'default',
        font_css:
            'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap',
        browser_spellcheck: true,
        contextmenu: false,
        setup: (editor: Editor) => {
            this.editorInstance = editor;
            editor.on('init', () => {
                this.ready$.next(true);
            });
        },
    };
    initialValue$ = new BehaviorSubject<string>('');
    ready$ = new BehaviorSubject<boolean>(false);
    private contentInit = false;
    private editorInstance: Editor | null = null;
    private lastInteractiveUpdate?: string;

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData,
        private currentThemeService: IThemeService,
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        // update content if form is updated with a value that does not math user's input
        // e.g. from a form condition that calls an api function and updates this form field value
        this.formControl?.valueChanges
            .pipe(
                startWith(''),
                map(this.convertForFormValue),
                filter((value) => value !== this.lastInteractiveUpdate),
                takeUntil(this.destroyed$),
            )
            .subscribe((value) => {
                const current = this.editorInstance?.getContent();
                if (current !== value) {
                    this.editorInstance?.setContent(value || '');
                }
            });

        this.currentThemeService.currentTheme$
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => {
                    this.editorConfig = {
                        ...this.editorConfig,
                        content_style: `
                        html {
                            font-size: ${this.currentThemeService.getThemeValue(
                                'root-font-size',
                            )};
                        }
                        body {
                            color: ${this.currentThemeService.getThemeValue(
                                'body-color',
                            )};
                            background-color: ${this.currentThemeService.getThemeValue(
                                'body-bg',
                            )};
                            font-family: ${DEFAULT_INPUT_RICH_TEXT_FONT_FAMILY};
                            font-size: ${this.currentThemeService.getThemeValue(
                                'body-font-size',
                            )};
                        }
                    `,
                    };
                }),
                delay(50),
            )
            .subscribe(() => {
                if (this.formElement?.formatsAccepted === 'Markdown') {
                    this.initialValue$.next(
                        this.convertToHtml(this.formControl?.value),
                    );
                } else {
                    this.initialValue$.next(this.formControl?.value);
                }
                this.ready$.next(true);
            });
    }

    convertToMarkdown(htmlValue: string): string {
        return new showdown.Converter().makeMarkdown(htmlValue || '');
    }

    convertToHtml(markdownValue: string): string {
        return new showdown.Converter().makeHtml(markdownValue || '');
    }

    onEditorChange(event: any) {
        const content = event.editor.getContent();
        if (this.contentInit) {
            this.formControl?.markAsDirty();
        } else {
            this.contentInit = true;
        }
        if (this.formControl?.dirty) {
            const convertedValue = this.convertForFormValue(content);
            this.lastInteractiveUpdate = convertedValue;
            this.formControl?.setValue(convertedValue);
        }
    }

    convertForFormValue(content: string) {
        if (!content) {
            return '';
        }
        if (!this?.formElement) {
            return content;
        }
        return this.formElement?.formatsAccepted === 'Markdown'
            ? this.convertToMarkdown(content)
            : content;
    }
}
