import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PushPipe } from '@ngrx/component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { WdxButtonModule } from '@wdx/shared/components/wdx-button';
import { WdxCheckboxModule } from '@wdx/shared/components/wdx-checkbox';
import { WdxCheckboxTileModule } from '@wdx/shared/components/wdx-checkbox-tile';
import { WdxCheckboxTileGroupModule } from '@wdx/shared/components/wdx-checkbox-tile-group';
import { WdxDocumentInfoComponent } from '@wdx/shared/components/wdx-document-info';
import { WdxFileUploadComponent } from '@wdx/shared/components/wdx-file-upload';
import { WdxFormFieldModule } from '@wdx/shared/components/wdx-form-field';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxIconStackModule } from '@wdx/shared/components/wdx-icon-stack';
import { WdxInputDateModule } from '@wdx/shared/components/wdx-input-date';
import { WdxInputDateRangeModule } from '@wdx/shared/components/wdx-input-date-range';
import { WdxInputGroupModule } from '@wdx/shared/components/wdx-input-group';
import { WdxInputTextModule } from '@wdx/shared/components/wdx-input-text';
import { WdxInputTimeModule } from '@wdx/shared/components/wdx-input-time';
import { WdxLabelModule } from '@wdx/shared/components/wdx-label';
import { WdxRadioModule } from '@wdx/shared/components/wdx-radio';
import { WdxRadioButtonModule } from '@wdx/shared/components/wdx-radio-button';
import {
    WdxRadioButtonGroupModule,
    WdxRadioGroupFormControlComponent,
} from '@wdx/shared/components/wdx-radio-button-group';
import {
    WdxRadioTileGroupModule,
    WdxRadioTileModule,
} from '@wdx/shared/components/wdx-radio-tile';
import {
    FileNamePipe,
    HighlightPipe,
    TranslateTokenisedStringPipe,
    WdxDisabledDirective,
    customCurrencyMaskConfig,
} from '@wdx/shared/utils';
import {
    NgxCurrencyDirective,
    provideEnvironmentNgxCurrency,
} from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { AddressesService } from '../../+state/addresses/addresses.service';
import { FormSummaryValuePipe, ValidationErrorMessagePipe } from '../../pipes';
import { TelephoneService } from '../../services';
import { FormValidationSummaryComponent } from '../form-validation-summary';
import { FormAddressControlComponent } from './form-address-control/form-address-control.component';
import { FormBooleanControlComponent } from './form-boolean-control/form-boolean-control.component';
import { FormCheckboxesControlComponent } from './form-checkboxes-control/form-checkboxes-control.component';
import { FormControlsContainerComponent } from './form-controls-container.component';
import { FormControlsMapperComponent } from './form-controls-mapper/form-controls-mapper.component';
import { FormCurrencyControlComponent } from './form-currency-control/form-currency-control.component';
import { FormDateControlComponent } from './form-date-control/form-date-control.component';
import { FormDocumentLookupControlComponent } from './form-document-lookup-control/form-document-lookup-control.component';
import { FormFileUploadControlComponent } from './form-file-upload-control/form-file-upload-control.component';
import { FormHierarchicalControlComponent } from './form-hierarchical-control/form-hierarchical-control.component';
import { FormInputControlComponent } from './form-input-control/form-input-control.component';
import { FormRadioButtonsControlComponent } from './form-radio-buttons-control/form-radio-buttons-control.component';
import { FormRichTextControlComponent } from './form-rich-text-control/form-rich-text-control.component';
import { FormSelectControlComponent } from './form-select-control/form-select-control.component';
import { FormTelephoneControlComponent } from './form-telephone-control/form-telephone-control.component';
import {
    FormWrappedControlComponent,
    FormWrappedControlDirective,
} from './form-wrapped-control';

@NgModule({
    declarations: [
        FormInputControlComponent,
        FormBooleanControlComponent,
        FormCheckboxesControlComponent,
        FormCurrencyControlComponent,
        FormDateControlComponent,
        FormRadioButtonsControlComponent,
        FormRichTextControlComponent,
        FormTelephoneControlComponent,
        FormFileUploadControlComponent,
        FormDocumentLookupControlComponent,
        FormWrappedControlComponent,
        FormWrappedControlDirective,
        FormSelectControlComponent,
        FormHierarchicalControlComponent,
        FormControlsContainerComponent,
        FormAddressControlComponent,
        FormControlsMapperComponent,
    ],
    imports: [
        CommonModule,
        EditorModule,
        NgSelectModule,
        NgxCurrencyDirective,
        NgxMaskModule.forRoot(),
        PushPipe,
        ReactiveFormsModule,
        WdxCheckboxModule,
        WdxCheckboxModule,
        WdxCheckboxTileGroupModule,
        WdxCheckboxTileGroupModule,
        WdxCheckboxTileModule,
        WdxCheckboxTileModule,
        WdxFormFieldModule,
        WdxIconModule,
        WdxIconButtonModule,
        WdxIconStackModule,
        WdxInputDateModule,
        WdxInputDateRangeModule,
        WdxInputTextModule,
        WdxInputTimeModule,
        WdxLabelModule,
        WdxRadioButtonGroupModule,
        WdxRadioButtonModule,
        WdxRadioModule,
        WdxRadioTileGroupModule,
        WdxRadioTileModule,
        WdxInputGroupModule,
        ValidationErrorMessagePipe,
        WdxFileUploadComponent,
        WdxDocumentInfoComponent,
        FileNamePipe,
        FormSummaryValuePipe,
        HighlightPipe,
        FormValidationSummaryComponent,
        WdxButtonModule,
        WdxDisabledDirective,
        WdxRadioGroupFormControlComponent,
        TranslateTokenisedStringPipe,
    ],
    providers: [
        AddressesService,
        TelephoneService,
        provideEnvironmentNgxCurrency(customCurrencyMaskConfig),
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    ],
    exports: [
        FormInputControlComponent,
        FormBooleanControlComponent,
        FormCheckboxesControlComponent,
        FormCurrencyControlComponent,
        FormDateControlComponent,
        FormRadioButtonsControlComponent,
        FormRichTextControlComponent,
        FormTelephoneControlComponent,
        FormFileUploadControlComponent,
        FormDocumentLookupControlComponent,
        FormWrappedControlComponent,
        FormSelectControlComponent,
        FormHierarchicalControlComponent,
        FormAddressControlComponent,
        FormControlsContainerComponent,
        FormControlsMapperComponent,
        WdxRadioGroupFormControlComponent,
    ],
})
export class FormControlsModule {}
